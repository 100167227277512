.project_grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;

}

.project_grid > div {
}





.project_item {
    position: relative;
    cursor: pointer;
    grid-column: span 2;     
}

.project_item:hover h1 {
    color: red;
}

.project_item img {
    width: 100%;
}


.project_item h1{
    text-align: left;
    margin: 0%;
    font-size: 15pt;
}


.project_item h2 {
    text-align: left;
    font-size: 10pt;
    margin: 0%;
    margin-bottom: 10px;
    color: grey;
    font-weight: 400;
}

.big_item {
    column-span: 2;
    grid-column: 1 / 3; /* span from grid column line 1 to 3 (i.e., span 2 columns) */
    grid-row: 1 / 3;    /* same concept, but for rows */
  
}

.item_type {
    font-size: 11pt;
    border: solid 1px black;
    width: fit-content;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    z-index: 5;
    margin: 2px;
}

.item_type:hover {
     background-color: black;
     color: white;
     cursor: pointer;
     border: solid 1px white;
}

.item_house {
    position: absolute;
    right: 0%;
    top: 0%;
    padding: 10px;
}

.selector {
    text-align: center;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.selected_item {
    background-color: blue !important;
}


.two_col {
    width: 100%;
    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    margin-bottom: 20px;

}


.three_col {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
}

.project_caption {
    text-align: left;
    text-align: justify;

    padding: 20px;
}
.project_details {
    text-align: left;
}
.project_caption h1 {
    font-size: 50pt;
    margin: 0px;
}
.project_caption h2 {
    font-size: 30pt;
    margin: 0px;

}

.big_titles {
    font-size: 50pt;
    margin: 0px;
}
.med_caption {
    font-size: 30pt;
    margin: 0px;
}
.project_caption p {
    font-size: 20pt;
    margin: 0px;
}

.project_page img {
    max-height: 90vh;
    max-width: 100%;
}

.project_page video {
    max-height: 90vh;
    max-width: 100%;

}


.large_caption {
    font-size: 30pt;
    font-weight: 400;
    text-align: left;
    padding: 30px;
    text-align: center;
}

.project_details .med_caption {
    display: inline-block;
}
.project_description {
    border-bottom: 1px solid black;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {

    .two_col {
        grid-template-columns: repeat(1, 1fr);
    }

}


