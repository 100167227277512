.about_container {
    text-align: left;
}

.interests{
    padding: 5px;
    display: inline-block;
    border: 1px solid grey;
    color: grey;
    border-radius: 5px;
    margin: 5px;
    font-size: 20pt;
}