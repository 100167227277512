.footer_container{
    width: 100%;
    border-top: solid 1px rgb(187, 187, 187);
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 10pt;
    text-align: left;
    color: rgb(180, 180, 180);
}

#copyright {
    margin: 10px;
}

.footer_right {
    float: right;
}

.footer_left {

}

.footer_links{
    text-decoration: none;
    text-decoration: underline;
    color: grey;
    margin: 10px;
}

.footer_links:hover {
    color: blue;
}