.navbar_container {
    width: 100%;
    z-index: 5;
    position: fixed;
    left: 0%;
    top: 0%;
    background-color: white;
    border-bottom: solid 1px rgb(187, 187, 187);
}

.navbar_item {
    display: inline-block;
    background-color: white;
}

.navbar_item:hover {
    background-color: rgb(255, 240, 240);
    cursor: pointer;
}
#home_button {
    float: left;

}

#home_button {
    height: 40px;
}

#home_button:hover {}
#info_button {
    float: right;
    padding: 10px;

}